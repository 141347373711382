var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: "".concat(_vm.isTakePhoto ? 'mb-0 border-info' : 'mb-50 mb-md-1 border-info-2', " "),
    style: "".concat(_vm.isTakePhoto ? 'box-shadow: 0px 4px 10px 0px #0000004D;' : ''),
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-column flex-md-row justify-content-md-between mb-50"
  }, [_c('div', {
    staticClass: "bg-blue-gradient w-100 mb-25 mb-md-0 d-flex"
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: "p-75 p-md-1 mr-25 mr-md-75 mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "DollarSignIcon",
      "size": _vm.isMobileView ? '20' : '30'
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('div', {
    class: "text-airline fw-700 mb-0 mb-md-25 ".concat(_vm.isMobileView ? 'font-medium-4' : 'font-medium-3')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetails')) + " "), _vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-info cursor-pointer",
    attrs: {
      "id": "header-tooltip-mobile-price",
      "icon": "InfoIcon",
      "size": "16"
    }
  }) : _vm._e()], 1), _vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "target": "header-tooltip-mobile-price",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetail')))])]) : _vm._e(), !_vm.isMobileView ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetail')) + " ")]) : _vm._e()], 1)]), !_vm.isTakePhoto ? _c('div', {
    class: _vm.isMobileView ? 'd-flex-center flex-wrap align-items-stretch' : 'd-flex flex-nowrap align-items-stretch'
  }, [!_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-customer-service-fee",
      modifiers: {
        "modal-customer-service-fee": true
      }
    }],
    class: "rounded-lg text-nowrap mr-25 ".concat(_vm.isMobileView ? 'px-75' : ''),
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    }
  }, [_c('span', {
    staticClass: "mr-25 text-nowrap align-middle"
  }, [_vm._v(_vm._s(_vm.$t('flight.setServiceFee')))]), !_vm.isMobileView ? _c('feather-icon', {
    attrs: {
      "icon": "EditIcon",
      "size": "12"
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-warning.window",
      modifiers: {
        "hover": true,
        "v-warning": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-lg mx-25",
    attrs: {
      "variant": "outline-warning",
      "title": "".concat(_vm.$t(_vm.showServiceFee ? 'hide' : 'show'), " ").concat(_vm.$t('flight.serviceCharge'))
    },
    on: {
      "click": function click($event) {
        _vm.showServiceFee = !_vm.showServiceFee;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "16"
    }
  })], 1) : _vm._e(), !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info.window",
      modifiers: {
        "hover": true,
        "v-info": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-lg mx-25",
    attrs: {
      "variant": "outline-info",
      "title": "".concat(_vm.$t(_vm.showBenefit ? 'hide' : 'show'), " ").concat(_vm.$t('flight.profit'))
    },
    on: {
      "click": function click($event) {
        _vm.showBenefit = !_vm.showBenefit;
      }
    }
  }, [_vm.showBenefit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1) : _vm._e()]), _c('ModalCustomServiceFee'), _c('Stack', {
    class: "".concat(_vm.isTakePhoto ? 'gap-2' : 'gap-3')
  }, _vm._l(_vm.selectedTrip, function (trip, index) {
    return _c('b-card', {
      key: index,
      staticClass: "border-secondary mb-0",
      attrs: {
        "header-class": "py-50 justify-content-center",
        "body-class": "px-25 pb-25"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            class: "fw-700 text-warning ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-3')
          }, [_vm._v(" " + _vm._s(_vm.getAirportByAirportCode(trip.departure.IATACode) ? "".concat(_vm.getAirportByAirportCode(trip.departure.IATACode).name, " (").concat(trip.departure.IATACode, ")") : trip.departure.IATACode) + " "), _c('span', {
            staticClass: "px-50"
          }, [_vm._v(" - ")]), _vm._v(" " + _vm._s(_vm.getAirportByAirportCode(trip.arrival.IATACode) ? "".concat(_vm.getAirportByAirportCode(trip.arrival.IATACode).name, " (").concat(trip.arrival.IATACode, ")") : trip.arrival.IATACode) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_vm.isTakePhoto && _vm.isMobileView ? _c('div', [_c('BRow', {
      staticClass: "text-uppercase"
    }, _vm._l(_vm.tripPriceColumns.filter(function (item) {
      return item.key !== 'Service_Charge';
    }), function (column) {
      return _c('BCol', {
        key: column.key,
        class: "".concat(column.key !== 'Passenger' ? 'text-right' : '', " text-nowrap"),
        attrs: {
          "cols": "3"
        }
      }, [_vm._v(" " + _vm._s(column.label) + " ")]);
    }), 1), _vm._l(_vm.resolvePriceData(trip), function (priceItem, priceIndex) {
      return _c('BRow', {
        key: priceIndex,
        staticClass: "fw-700"
      }, [_c('BCol', {
        attrs: {
          "cols": "3"
        }
      }, [_c('span', {
        class: "text-dark align-middle text-nowrap mb-0 ".concat(_vm.isMobileView && _vm.isTakePhoto ? 'text-right' : '')
      }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(priceItem.paxType))) + " x" + _vm._s(priceItem.numPaxType) + " ")])]), _c('BCol', {
        attrs: {
          "cols": "3"
        }
      }, [priceItem.discount || _vm.showPromotion && priceItem.promotion ? _c('div', [_c('h6', {
        staticClass: "text-secondary text-right text-nowrap"
      }, [_c('del', {
        staticClass: "text-danger"
      }, [_vm._v(_vm._s(_vm.formatCurrency(priceItem.fare)))])]), _c('div', {
        staticClass: "text-dark text-right text-nowrap"
      }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(priceItem.fare - priceItem.discount - priceItem.promotion) : _vm.formatCurrency(priceItem.fare - priceItem.discount)) + " ")])]) : priceItem.paxType === 'Infant' && !priceItem.totalSurcharge && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !priceItem.fare) ? _c('div', [_c('b-spinner', {
        staticClass: "text-right",
        attrs: {
          "variant": "primary",
          "label": "Text Centered",
          "small": "",
          "tag": "span"
        }
      })], 1) : _c('div', {
        staticClass: "text-dark text-right text-nowrap"
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.fare)) + " ")])]), _c('BCol', {
        attrs: {
          "cols": "3"
        }
      }, [!priceItem.totalSurcharge && priceItem.paxType === 'Infant' && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !priceItem.fare) ? _c('div', [_c('b-spinner', {
        staticClass: "text-right",
        attrs: {
          "variant": "primary",
          "label": "Text Centered",
          "small": "",
          "tag": "span"
        }
      })], 1) : _c('div', {
        staticClass: "text-dark text-right text-nowrap"
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.showServiceFee ? priceItem.totalSurcharge : priceItem.totalSurcharge + priceItem.serviceFee)) + " ")])]), _c('BCol', {
        attrs: {
          "cols": "3"
        }
      }, [!priceItem.totalSurcharge && priceItem.paxType === 'Infant' && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !priceItem.fare) ? _c('div', [_c('b-spinner', {
        staticClass: "text-right",
        attrs: {
          "variant": "primary",
          "label": "Text Centered",
          "small": "",
          "tag": "span"
        }
      })], 1) : _c('div', {
        staticClass: "text-dark text-right text-nowrap"
      }, [priceItem.discount || _vm.showPromotion && priceItem.promotion ? _c('del', {
        staticClass: "text-danger"
      }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(priceItem.total + priceItem.discount + priceItem.promotion) : _vm.formatCurrency(priceItem.total + priceItem.discount)))]) : _vm._e(), _c('div', {
        staticClass: "text-dark"
      }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(priceItem.total) : _vm.formatCurrency(priceItem.total + priceItem.promotion)) + " ")])])])], 1);
    })], 2) : _c('b-table-lite', {
      staticClass: "mb-1 rounded",
      attrs: {
        "bordered": "",
        "responsive": "",
        "outlined": "",
        "hover": "",
        "small": "",
        "thead-class": "text-body text-center fw-800 text-nowrap",
        "tbody-class": "text-body text-center font-weight-bolder text-nowrap",
        "fields": _vm.showServiceFee ? _vm.tripPriceColumns : _vm.tripPriceColumns.filter(function (item) {
          return item.key !== 'Service_Charge';
        }),
        "items": _vm.resolvePriceData(trip)
      },
      scopedSlots: _vm._u([{
        key: "cell(Passenger)",
        fn: function fn(data) {
          return [_c('h5', {
            class: "text-dark align-middle text-nowrap mb-0 ".concat(_vm.isMobileView && _vm.isTakePhoto ? 'text-right' : '')
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.item.paxType))) + " x" + _vm._s(data.item.numPaxType) + " ")])];
        }
      }, {
        key: "cell(Price)",
        fn: function fn(data) {
          return [data.item.discount || _vm.showPromotion && data.item.promotion ? _c('div', [_c('h6', {
            staticClass: "text-secondary text-right text-nowrap"
          }, [_c('del', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.formatCurrency(data.item.fare)))])]), _c('div', {
            staticClass: "text-dark text-right text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.fare - data.item.discount - data.item.promotion) : _vm.formatCurrency(data.item.fare - data.item.discount)) + " ")])]) : data.item.paxType === 'Infant' && !data.item.totalSurcharge && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !data.item.fare) ? _c('div', [_c('b-spinner', {
            staticClass: "text-right",
            attrs: {
              "variant": "primary",
              "label": "Text Centered",
              "small": "",
              "tag": "span"
            }
          })], 1) : _c('div', {
            staticClass: "text-dark text-right text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.fare)) + " ")])];
        }
      }, {
        key: "cell(Tax)",
        fn: function fn(data) {
          return [!data.item.totalSurcharge && data.item.paxType === 'Infant' && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !data.item.fare) ? _c('div', [_c('b-spinner', {
            staticClass: "text-right",
            attrs: {
              "variant": "primary",
              "label": "Text Centered",
              "small": "",
              "tag": "span"
            }
          })], 1) : _c('div', {
            staticClass: "text-dark text-right text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.showServiceFee ? data.item.totalSurcharge : data.item.totalSurcharge + data.item.serviceFee)) + " ")])];
        }
      }, {
        key: "cell(Service_Charge)",
        fn: function fn(data) {
          return [!data.item.totalSurcharge && data.item.paxType === 'Infant' && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !data.item.fare) ? _c('div', [_c('b-spinner', {
            staticClass: "text-right",
            attrs: {
              "variant": "primary",
              "label": "Text Centered",
              "small": "",
              "tag": "span"
            }
          })], 1) : _c('div', {
            staticClass: "text-dark text-right text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.serviceFee) || 0) + " ")])];
        }
      }, {
        key: "cell(Total)",
        fn: function fn(data) {
          return [!data.item.totalSurcharge && data.item.paxType === 'Infant' && (['AK'].includes(trip.source) && !_vm.draftBookingInfantPrice || ['TH'].includes(trip.source) && !data.item.fare) ? _c('div', [_c('b-spinner', {
            staticClass: "text-right",
            attrs: {
              "variant": "primary",
              "label": "Text Centered",
              "small": "",
              "tag": "span"
            }
          })], 1) : _c('div', {
            staticClass: "text-dark text-right text-nowrap"
          }, [data.item.discount || _vm.showPromotion && data.item.promotion ? _c('del', {
            staticClass: "text-danger"
          }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.total + data.item.discount + data.item.promotion) : _vm.formatCurrency(data.item.total + data.item.discount)))]) : _vm._e(), _c('div', {
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.total) : _vm.formatCurrency(data.item.total + data.item.promotion)) + " ")])])];
        }
      }], null, true)
    }), _c('div', {
      staticClass: "d-flex flex-wrap flex-md-nowrap",
      class: _vm.isMobileView ? 'flex-column' : 'justify-content-between px-1'
    }, [_c('div', {
      staticClass: "flex-grow-1",
      class: _vm.isMobileView ? 'mx-1' : 'mr-2'
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showBenefit,
        expression: "showBenefit"
      }]
    }, [_c('div', {
      staticClass: "d-flex-between",
      staticStyle: {
        "min-width": "250px"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('flight.promotion')) + ":")]), _c('strong', {
      staticClass: "font-medium-1"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalPriceTrip(trip, _vm.dataSearchFlight).promotions)) + " ")])]), _c('div', {
      staticClass: "d-flex-between",
      staticStyle: {
        "min-width": "250px"
      }
    }, [_c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('flight.profit')) + ":")]), _c('div', [_c('em', [_c('small', [_vm._v(" ("), !_vm.showPromotion ? _c('span', [_vm._v(_vm._s(_vm.$t('flight.promotion')) + " + ")]) : _vm._e(), _vm._v(_vm._s(_vm.$t('flight.serviceCharge'))), !trip.fareOptions.hideFee ? _c('span', [_vm._v(" - " + _vm._s(_vm.$t('flight.agencyFee')))]) : _vm._e(), _vm._v(") ")])])])]), _c('strong', {
      staticClass: "font-medium-1"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalPriceTrip(trip, _vm.dataSearchFlight).benefit)) + " ")])])])]), _c('div', {
      class: "flex-grow-1 ".concat(_vm.isMobileView ? '' : 'ml-2')
    }, [_c('div', {
      staticClass: "d-flex flex-column justify-content-end align-items-end fw-700"
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-md-row mr-1 mr-md-0 align-items-center"
    }, [_c('div', {
      staticClass: "text-nowrap mr-1"
    }, [_c('span', {
      staticClass: "mr-1 font-medium-2 text-danger"
    }, [_vm._v(_vm._s(_vm.$t('flight.total')) + " : ")]), _c('span', {
      staticClass: "font-medium-2 text-danger"
    }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.getTotalPriceTrip(trip, _vm.dataSearchFlight).total)))])]), _vm.showBenefit ? _c('div', {
      staticClass: "mr-1 mr-md-0 text-warning"
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "InfoIcon"
      }
    }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.noteDiscount')))])], 1) : _vm._e()])])])])], 1);
  }), 1), !_vm.isEmpty(_vm.addonDetails) && !_vm.isTakePhoto ? _c('b-card', {
    staticClass: "border-secondary mt-1",
    attrs: {
      "body-class": "px-0 py-75"
    }
  }, [_c('div', {
    staticClass: "font-medium-3 fw-700 mb-50 px-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Service')) + " ")]), _c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "thead-class": "text-body text-nowrap fw-800",
      "tbody-class": "text-body font-weight-bolder",
      "items": _vm.addonDetails,
      "fields": _vm.addonsPriceColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.addonsPriceColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-body"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.label))) + " ")])])];
        }
      };
    }), {
      key: "cell(Passenger)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-nowrap",
          class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
        }, [_vm._v(" " + _vm._s(item.firstName && item.lastName ? "".concat(item.lastName, " ").concat(item.firstName) : "".concat(item.paxType, " #").concat(item.paxId)) + " ")])];
      }
    }, {
      key: "cell(flight_segment)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.flights) + " ")];
      }
    }, {
      key: "cell(Airline)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.airline ? _vm.getAirlineNameByCode(item.airline) : item.airline) + " ")])];
      }
    }, {
      key: "cell(Service)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticStyle: {
            "min-width": "170px"
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(item.serviceName)) + " ")])];
      }
    }, {
      key: "cell(Price)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.addonPrice)) + " ")];
      }
    }, {
      key: "cell(Amount)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-body"
        }, [_c('div', {
          staticClass: "d-flex justify-content-center align-items-center"
        }, [_c('span', {
          staticClass: "d-inline-block",
          staticStyle: {
            "min-width": "25px"
          }
        }, [_vm._v(" " + _vm._s(data.item.amount) + " ")])])])];
      }
    }, {
      key: "cell(Action)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [!_vm.isHideFunction && !(['JQ'].includes(item === null || item === void 0 ? void 0 : item.source) && ['LEGX'].includes(item === null || item === void 0 ? void 0 : item.code)) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded p-50",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('handle-delete-service', item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Trash2Icon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: "d-flex justify-content-between align-items-end fw-700"
  }, [_vm.isWarningWeightBaggageAK ? _c('b-col', [_c('b-alert', {
    staticClass: "my-0 fw-700 mx-1 p-50 text-center",
    staticStyle: {
      "min-width": "150px"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Trọng lượng tối đa của hành lý là 32kg mỗi kiện ")])], 1) : _vm._e(), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end py-50"
  }, [_c('div', {
    staticClass: "d-flex flex-nowrap mr-1"
  }, [_c('span', {
    staticClass: "mr-1 font-medium-2 text-nowrap text-danger"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')) + " : "), _c('span', {
    staticClass: "font-medium-2 text-danger"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalPriceAddon)))])]), _c('div', {
    staticClass: "text-nowrap mr-md-0"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])])], 1)], 1) : _vm._e(), !_vm.isTakePhoto ? _c('b-card', {
    class: "border-secondary mt-1 ".concat(_vm.isEmpty(_vm.selectedSeatsDetail) ? 'd-none' : ''),
    attrs: {
      "body-class": "px-0 py-75"
    }
  }, [_c('div', {
    staticClass: "font-medium-3 fw-700 mb-50 px-1"
  }, [_vm._v(" Chỗ ngồi ")]), _c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "thead-class": "text-body text-nowrap fw-800",
      "tbody-class": "text-body text-nowrap font-weight-bolder",
      "items": _vm.selectedSeatsDetail,
      "fields": _vm.seatsPriceColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.seatsPriceColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(Passenger)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.passenger.firstName && data.item.passenger.lastName ? "".concat(data.item.passenger.lastName, " ").concat(data.item.passenger.firstName) : "".concat(data.item.passenger.paxType, " #").concat(data.item.passenger.paxId)) + " ")];
      }
    }, {
      key: "cell(flight_segment)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.segment.segmentFlights) + " ")];
      }
    }, {
      key: "cell(Airline)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(item.segment.airline)) + " ")];
      }
    }, {
      key: "cell(Seat)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.seat.seat.seatName) + " ")];
      }
    }, {
      key: "cell(Price)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(!_vm.isEmpty(item.seat.seat.fares) && item.seat.seat.fares[0].paxType === 'ALL' ? _vm.formatCurrency(item.seat.seat.fares[0].total) : !_vm.isEmpty(item.seat.seat.fares) && item.seat.seat.fares.find(function (f) {
          return f.paxType === item.passenger.paxType;
        }) ? _vm.formatCurrency(item.seat.seat.fares.find(function (f) {
          return f.paxType === item.passenger.paxType;
        }).total) : 0) + " ")];
      }
    }, {
      key: "cell(Action)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [!_vm.isHideFunction ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded p-50",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteSeat(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Trash2Icon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "d-flex flex-column justify-content-end align-items-end fw-700 mr-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-md-row mr-1 mr-md-0 align-items-center"
  }, [_c('div', {
    staticClass: "text-nowrap mr-1"
  }, [_c('span', {
    staticClass: "mr-1 font-medium-2 text-danger"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')) + " : ")]), _c('span', {
    staticClass: "font-medium-2 text-danger"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalPriceSeatPrice)))])]), _c('div', {
    staticClass: "mr-1 mr-md-0"
  }, [_c('feather-icon', {
    staticClass: "text-warning mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap text-italic text-warning font-small-3"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareText')))])], 1)])])], 1) : _vm._e(), _vm.isTakePhoto && _vm.totalPriceAddon + _vm.totalPriceSeatPrice > 0 ? _c('div', {
    staticClass: "text-right fw-700 mr-md-1"
  }, [_c('span', {
    class: "".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Service')) + ": ")]), _c('span', {
    staticClass: "text-warning font-medium-5"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceAddon + _vm.totalPriceSeatPrice)) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "text-right fw-700 mr-md-1"
  }, [_c('span', {
    class: "".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.totalFare')) + ": ")]), _c('span', {
    staticClass: "text-warning font-medium-5"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalPriceTicket(_vm.selectedTrip, _vm.dataSearchFlight) + _vm.totalPriceAddon + _vm.totalPriceSeatPrice)) + " ")]), _c('span', {
    staticClass: "text-nowrap text-italic font-small-3"
  }, [_vm._v("(" + _vm._s(_vm.$t('flight.fareText')) + ")")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }